
import {LiveMessage} from "./LiveMessage";

class Form
{
    constructor ( _$form, _bHandleSubmit = true, _closureSubmit = null )
    {
        console.log("Form::Form", _$form);
        
        if ( !_$form.is('form') )
        {
            return false;
        }
        
        this.$form = _$form;
		this.$fieldWraps = this.$form.find('.field_wrap');
		this.$inputs = this.$fieldWraps.find('input[type="radio"]');

		this.$inputs.each(function(_i, _el){
			console.log('radio', _el);
		    let $this = $(_el);
			if( $this.attr('data-show') )
			{
				if ( $this.prop('checked') )
				{
					if ( $this.val() == 'hide' )
					{
						$( '.' + $this.attr('data-show') ).slideUp();
					}
				}
				$this.on('change', this.changeRadio);
			}
		}.bind(this));

        this.bindEvents(_bHandleSubmit, _closureSubmit);
    }
    
    bindEvents (_bHandleSubmit, _closureSubmit)
    {
        this.$form.on('focus', 'fieldset input[type="text"], fieldset input[type="password"], fieldset select, fieldset textarea', Form.setFocus);
        this.$form.on('blur', 'fieldset input[type="text"], fieldset input[type="password"], fieldset textarea', Form.removeFocus);
        this.$form.on('change blur', 'fieldset select', Form.changeSelect);
        this.$form.on('keyup', 'input, textarea', Form.checkLength);
        
        if (_bHandleSubmit)
        {
            console.log("handling form submit");
            this.$form.submit(this.submit.bind(this));
            
        }
        else if (_closureSubmit != null) //Submit is handle by another class
        {
            this.$form.submit(_closureSubmit);
            
        }
        
        this.$form.find("[data-type='uploader']").each(this.bindUploader.bind(this));

    }

	changeRadio ( _e )
    {
        let $this = $( _e.target );

        if ( $this.prop('checked') )
        {
            if ( $this.val() == 'show' )
            {
                $( '.' + $this.attr('data-show') ).slideDown();
            }
            else
            {
                $( '.' + $this.attr('data-show') ).slideUp();
            }
        }
    }

    bindUploader ($_index, _el)
    {
      
        console.log("bindUploader");
       
        let $btn = $(_el);
        
        let strID = $btn.attr("id");
        
        let $progress = $btn.siblings(".progress_container");
        
        $progress.find(".progress").css("width", "0%");
        
        let strLang = this.$form.find("#lang").val();
        
        let strMode = "as-is";
        
        let objParams = {chunksFolder: "chunks", tempFolder: "temp", saveFolder : "files", "mode": strMode };
        
        let arrValidations = ['jpeg', 'jpg', 'gif', 'png', 'pdf'];
        
        let strValidation = $btn.attr("data-mimetype");
        
        if (strValidation != "")
        {
            arrValidations = strValidation.split("|");
        }
        
        
        
        var options  =
            {
                button: $btn[0],
                
                request:
                    {
                        endpoint: `/api/upload/endpoint`,
                        params: objParams
                    },
                
                
                chunking:
                    {
                        enabled: true,
                        partSize: 1000000,
                        success :
                            {
                                endpoint: `/api/upload/endpoint?done`,
                                params: objParams
                            }
                        
                    },
                
                callbacks:
                    {
                        onComplete: this.onUploadComplete.bind(this, $btn),
                        onSubmitted: this.onUploadSubmitted.bind(this, $btn),
                        onProgress: this.onUploadProgress.bind(this, $btn),
                        onError: this.onUploadError.bind(this, $btn),
                    },
                
                validation:
                    {
                        allowedExtensions: arrValidations
                    }
            };
        
        this.uploader = new qq.FineUploaderBasic (options);
    }
    
    onUploadError  ($_btn,  _strID, _strName, _strError)
    {
        console.log("onUploadError");
        console.log(arguments);
        let strLang = this.$form.find("#lang").val();
        let strMessage = strLang == "fr" ? "Impossible de téléverser ce fichier" : "Cannot upload this file";
        
        Form.addError($_btn,strMessage);
    }
    
    onUploadComplete  ($_btn, _strID, _strName, _json)
    {
       
        let $this = $_btn.find(".file_upload");
        $this.removeClass('is-working').addClass('is-done');
        $this.find('div.progress').css('width', '100%');
        
        _json.data["filetype"] = "file";
        
        let strLang = this.$form.find("#lang").val();
        
        let objParams = $.param(_json.data)
        
        if (_json.success == 1)
        {
            //console.log("Finalizing");
            $.ajax
            (
                {
                    url: `/api/upload/finalize`,
                    data : objParams,
                    dataType: "JSON",
                    method: "POST"
                }
            ).done (this.onUploadFinalize.bind(this, $_btn));
            
            //var $inputText = $_divUploader.find("[data-wc-type='text-input-image']");
            //$inputText.val("");
            
        }
        else
        {
            //PLACMS.Notification.spawn(_json);
            //_this.resetFileUploader($_divUploader);
        }
    }
    
    onUploadProgress  ($_btn, _strID, _strName, _iBytesLoaded, _iBytesTotal)
    {
        console.log("onUploadProgress");
        console.log(arguments);
        let iRatio = Math.round(_iBytesLoaded / _iBytesTotal *100);
        
       /* $_progress.find(".progress").css("width", iRatio + "%");*/
    
        let $this = $_btn.find(".file_upload");
        let strProgress = iRatio.toString() + '%';
    
        $this.addClass('is-working');
    
        $this.find('div.progress').css('width', strProgress);
        $this.find('p.working').text( strProgress );
    }
    
    onUploadSubmitted  ($_btn, _strID, _strName)
    {
        console.log("onUploadSubmitted");
        console.log(arguments);
        let $this = $_btn.find(".file_upload");
        let strProgress = 0 + '%';
        
        $this.find('div.progress').css('width', strProgress);
        $this.find('p.working').text( strProgress );
        
        Form.clearError($_btn);
    }
    
    onUploadFinalize  ($_btn, _json, _strMessage, _response  )
    {
    
        console.log("onUploadFinalize");
        console.log(arguments);
        
        if (_json.success == 1)
        {
           // let strID = $_btn.attr("data-field");
            let $input = $_btn.find("input[type=hidden]");
            let strURI = _json.data.uri;
            
            $input.val(strURI);
            
            
        }
        
        //$_btn.removeAttr("disabled");
        
    }
    
    
    
    
    
    static addError ( _$el, _strMsg)
    {
        Form.clearError(_$el);
        let $fieldset = _$el.parents('fieldset');

        let idError = _$el.attr('id')+'-error';
        _$el.attr('aria-describedby', idError);
        $fieldset.append('<p id="'+idError+'" class="error">' + _strMsg + '</p>');
        $fieldset.addClass('error');
    }
    
    static clearAllErrors ( _$form )
    {
        _$form.find('fieldset.error').removeClass("error");
        _$form.find('p.error').remove();

        let $inputs = _$form.find('input, textarea');
        $inputs.removeAttr('aria-describedby');
		$inputs.each(function(_i, _el){
			let $el = $(_el);
			let strDataAriaDescribedby = $el.attr('data-aria-describedby');
			if(strDataAriaDescribedby != undefined && strDataAriaDescribedby != "") {
				$el.attr('aria-describedby', strDataAriaDescribedby);
			}
		}.bind(this));
    }
    
    static changeSelect ( _e )
    {
        
        let $this = $(_e.target);
        let $fieldset = $this.parents('fieldset');
        if ( ! $this.is('select') )
        {
            $this = $fieldset.find('select');
        }
        if ( $this.val() == '' )
        {
            $fieldset.removeClass('has-content');
        }
        else
        {
            $fieldset.addClass('has-content');
        }
        
    }
    
    static setFocus ( _e )
    {
        
        let $this = $(_e.target);
		$this.parents('fieldset').addClass('has-content');
    
        console.log('setFocus');
        console.log($this);
    }
    
    static clearError ( _$el )
    {
        console.log('clearError');
        let $fieldset = _$el.parents('fieldset');
        $fieldset.addClass('has-content');
        $fieldset.removeClass('has-error');
        $fieldset.find('.error').remove();
    }
    
    static removeFocus ( _e )
    {
        console.log('removeFocus');
        let $this = $(_e.target);
        let $fieldset = $this.parents('fieldset');
        if ( ! ($this.is('input') || $this.is('textarea')) )
        {
            $this = $fieldset.find('input, textarea');
        }
        if ( $this.val() == '' )
        {
            $fieldset.removeClass('has-content');
        }

		
		if ( $.trim( $this.val() ) == '' )
		{
			$this.parents('fieldset').removeClass('has-content');
		}
    }

    static checkLength ( _e )
    {
        let $field = $(_e.target);
        let strValue = $field.val();
        let iMaxLength = parseInt($field.attr('maxlength'));
        console.log( 'checkLength', $field, strValue, iMaxLength);
        if ( strValue.length >= iMaxLength )
        {
            _e.preventDefault();
            LiveMessage.outputMsg( $field.attr('data-label-maxlength-reached') );
        }
    }

    displayError (_index, _data)
    {
        if (_data.success != 0)
            return;
        
        let $field = $("#" + _data.field);

        Form.addError($field, _data.message);
        
    }
    
    submit (_e)
    {
        _e.preventDefault();
        
        Form.clearAllErrors(this.$form);
        
        let strLang = this.$form.find("#lang").val();
        
        let objData = this.$form.serialize();
        
        $("#btn_submit").prop("disabled", true);
        
        $.ajax(
            {
                url:`/api/form`,
                data: objData,
                dataType:"JSON",
                method:"POST"
            }
        )
            .done (this.onSubmit.bind(this));

    }
    
    onSubmit (_data)
    {

        
        if (_data.success == 1)
        {
            this.onSuccess(_data);
            
            return;
        }
        
        let arrState = _data.state;
        
        $.each(arrState, this.displayError.bind(this));
        
        let $firstError = this.$form.find('fieldset.error').first();
        

        $firstError.find("input").focus();
        
        let iScrollTo = $firstError.offset().top - 20;
        
        
        $('html, body').animate({
            scrollTop: iScrollTo
        }, 250, this.debug.bind(this));
        
        console.log($('html, body').scrollTop());
    
        $("#btn_submit").prop("disabled", false);

    }
    
    debug ()
    {
        console.log("debug");
        console.log($('html, body').scrollTop());
    }
    onSuccess (_data)
    {
        console.log("onSucess");
    
        $('html, body').animate({
            scrollTop: 434
        }, 1000, this.removeForm.bind(this));
 
    }
    
    removeForm ()
    {
        console.log("removeForm");
        this.$form.hide();
        $("#retroaction").show();
        $(".instructions").hide();
    }
    
    scrollToTop ()
    {
        console.log("scrollToTop");
        console.log($("#externalIframe_168"));
        console.log($("html"));
        console.log($("body"));
        
        let $iframe = $("body").parent();
        
        console.log($iframe);
        
        $("html,body").scrollTop = 0;

    }
}

export {Form};