let $live = null;
let iDelay = 500;
let bCanOutput = true;
class LiveMessage
{
	static outputMsg ( _strMsg )
	{
		if ( $live === null )
		{
			$live = $('#live');
		}
		$live.html( _strMsg );
	}
}

export {LiveMessage};