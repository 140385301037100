import {Form} from './Form.js';


class Main
{
	constructor ()
	{
		new Form($('#form_subscribe'));
	}
}

$(function () {
	new Main();
});